@import "../../scss/bootstrap";

#introduction {
    background-color: $secondary-bg-color;

    a {
        color: $body-color;

        &:hover {
            color: tint-color($body-color, 30%);
        }
    }
}

#letter-wrapper {
    border: 1px solid $body-color;
    padding: 2rem 32px;
    font-family: Literata, serif;
    max-width: 600px;
}