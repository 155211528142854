@import "../../scss/bootstrap.scss";

#name-title {
    font-family: 'Slabo 27px', serif;
    font-size: 5rem;
    line-height: 5rem;
}

#job-role {
    font-family: 'Gentium Plus', serif;
    font-style: italic;
    font-size: 1.25rem;
    line-height: 1.25rem;
}

#brief-intro {
    margin-top: 32px;
    font-family: 'Duru Sans', sans-serif;
    font-size: 1.25rem;
    @include media-breakpoint-up(sm) {
        max-width: 50vw;
    }
}

#scroll-down-for-more {
    text-decoration: none;
    color: $body-color;
    font-family: Literata, serif;
    padding: 0.5rem 12px;
    border: 2px solid $body-color;
}