@import "../scss/bootstrap";
footer {
    ul {
        list-style-type:none;
        margin: 0;
    }
    * {
        color: $footer-color;
    }
    background-color: $footer-bg-color;
    a {
        font-family: Literata, serif;
        color: $footer-color;
        text-decoration: none;
        &:hover {
            color: shade-color($footer-color, 30%);
        }
    }
}