@import "../scss/bootstrap.scss";

nav.navbar {
    background-color: $header-bg-color;
    color: $header-color;
    font-family: Lato, 'Courier New', Courier, monospace;
    font-size: 1.25rem;

    a {
        padding: 0;
    }
}