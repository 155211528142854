// Customize some defaults
$body-color: #1b170c;
$body-bg: #ecf0f1;
$border-color: #0a0906;

$secondary-text-color: #ffffff;
$secondary-bg-color: #d9e0e4;
$dark-text-color: #748cab;

$header-bg-color: #2c3e50;
$header-color: #ffffff;

$footer-bg-color: #34495e;
$footer-color: #ffffff;

$card-border-radius: 0;
$card-border-color: rgba($border-color, 0.2);

$badge-bg-color: $footer-bg-color;
$badge-color: $footer-color;

// Temp
$color-1: #f0ebd8;
$color-3: #3e5c76;
$color-4: #1d2d44;
$color-5: #0d1321;
