@import '../../scss/bootstrap.scss';

#capabilities h2, #aims h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    padding-left: 32px;
}

#capabilities ul, #aims ul {
    font-family: Karma, serif;
    font-size: 1.25rem;
    list-style-type: none;
    padding: 0;
    li {
        padding-left: 54px;
        min-height: 2.75rem;
        line-height: 2.75rem;
        border-top: 1px solid $body-color;
    }
    li:last-child {
        border-bottom: 1px solid $body-color;
    }
}

@include media-breakpoint-up(md) {
    #capabilities ul, #aims ul {
        li {
            padding-left: 64px;
            min-height: 3rem;
            line-height: 3rem;
        }
    }
}